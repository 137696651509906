<template>
  <v-container class="pa-0" grid-list-md>
    <v-flex xs12 class="pa-0" style="position: relative;"> 
      <div id="messages" :class="conversations.length == 0 ? 'chat-container' : 'white chat-container'" ref="chatContainer">
        <div
          v-for="(convo, index) in conversations" :key="index"
          :class="convo.user._id != $store.getters['user/user'].user._id ? 'message person-1 text-wrap' : 'message person-2 text-wrap'"
        >
          <v-hover v-if="!convo.isFile">
            <v-tooltip
              :left="convo.user._id === $store.getters['user/user'].user._id"
              :right="convo.user._id != $store.getters['user/user'].user._id"
            >
              <template v-slot:activator="{ on }">
              <v-card-actions v-if="!convo.isFile" class="align-start pa-0">
                <v-avatar
                  v-if="convo.user._id != $store.getters['user/user'].user._id"
                  :size="28"
                  class="mr-2"
                  color="grey"
                ><img :src="convo.user.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'" alt="avatar">
                </v-avatar>
                <v-card-text v-on="on" :class="convo.user._id != $store.getters['user/user'].user._id ? 'primary white--text messageBox px-2 pb-2 pt-1' : 'grey lighten-3 messageBox px-2 pb-2 pt-1'">
                  <p v-for="line in convo.message.split('\n')" :key="line.key" class="caption pt-1 mb-0">{{ line }}</p>
                </v-card-text>
              </v-card-actions>
              <v-row
                v-else
                :justify="convo.user._id != $store.getters['user/user'].user._id ? 'start' : 'end'"
                no-gutters
              >
                <v-avatar
                  v-if="convo.user._id != $store.getters['user/user'].user._id"
                  :size="28"
                  class="mr-2"
                  color="grey"
                ><img :src="convo.user.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'" alt="avatar">
                </v-avatar>
                <v-img
                  @click="openGallery(convo.message)" 
                  :src="convo.message"
                  class="messageBox"
                  max-width="80%"
                  max-height="124"
                >
                </v-img>
              </v-row>
              </template>
              <p class="mb-0" style="font-size: 10px;">{{ convo.createdAt | dateToWords }}</p>
            </v-tooltip>
          </v-hover>
        </div>
        <v-row v-if="conversations.length == 0" justify="center" align="center" no-gutters style="height: 76vh;">
            <v-col cols="6" class="text-center">
              <v-progress-linear
                color="deep-purple accent-4"
                height="6"
                class="mb-4"
                indeterminate
                rounded
              ></v-progress-linear>
              <p class="mb-0 caption">Fetching Messages ...</p>
            </v-col>
          </v-row>
      </div>
      <MessageArea v-if="!isFinish && paymentStatus === 'Payment Verified'" />
    </v-flex>
    
    <v-dialog
      v-model="dialog"
      width="700"
      @keydown.esc="dialog = false, selectedImage = ''"
      persistent
    >
      <v-card-actions class="primary"> 
        <v-icon @click="downloadFile(selectedImage)" color="white">mdi-download</v-icon>
        <v-spacer></v-spacer>
        <v-icon color="white" @click="dialog = false, selectedImage = ''">mdi-close</v-icon>
      </v-card-actions>
      <v-img
        :src="selectedImage"
        contain
      >
      </v-img>
    </v-dialog>
  </v-container>
</template>

<script>
import MessageArea from '@/components/BoardComponents/NewMessageArea'
import io from 'socket.io-client'
import moment from 'moment'

export default {
  name: 'DocumentConversations',
  components: {
    MessageArea
  },
  props: {
    isFetching: {
      type: Boolean
    },
    isError: {
      type: Boolean
    },
    isFinish: {
      type: Boolean
    },
    paymentStatus: {
      type: String
    }
  },
  data () {
    return {
      dialog: false,
      isDeleting: false,
      error: false,
      on: '',
      selectedImage: '',
      socket: io(process.env.VUE_APP_SERVER),
    }
  },
  methods: {
    downloadFile (fileUrl) {
      window.location.href = fileUrl
    },
    scrollToBottom () {
      var area = this.$el.querySelector("#messages")
      area.scrollTop = area.scrollHeight
    },
    openGallery (imgSrc) {
      console.log(imgSrc)
      this.$emit('openGallery', imgSrc)
    }
  },
  updated () {
    this.scrollToBottom()
  },
  computed: {
    conversations () {
      return this.$store.getters['legalAdvice/conversations']
    },
    legalAdvice () {
      return this.$store.getters['legalAdvice/legalAdvice']
    }
  },
  filters: {
    dateToWords: function (value) {
      return value ? moment(value).format('MMMM DD YYYY, hh:mm A') : ''
    }
  },
  watch: {
    conversations () {
      this.scrollToBottom()
    }
  }
}
</script>

<style scoped>
  .message {
    padding: 0px;
    margin: 16px 6px 0;
    max-width: 80%;
    border-radius: 5px;
  }
  .person-1 {
    float: left;
    clear: both;
  }
  .person-2 {
    float: right;
    clear: both;
  }
  .person-1 + .person-1,
  .person-2 + .person-2 {
    margin: 3px 8px 0;
  }
  .messageBox {
    border-radius: 12px;
  }
  .scroll {
    overflow-y: auto;
    padding-top: 2%;
    overflow-x: hidden;
    scroll-behavior: auto;
    scroll-behavior: smooth;
  }

  .chat-container{
    padding-bottom: 18px;
    height: calc(100vh - 10rem);
    overflow-y: auto;
    background-color: #f2f2f2;
  }
</style>