<template>
  <div>
     <v-row v-if="!isFetching" no-gutters>
      <v-col
        v-for="(image, index) in images"
        :key="index"
        class="d-flex child-flex px-1 py-1"
        cols="4"
      >
        <v-card flat tile class="d-flex">
          <v-img
            :src="image.message"
            lazy-src="https://picsum.photos/10/6?image=6"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LegalAdviceService from '@/services/LegalAdviceServices'

export default {
  name: 'Images',
  data () {
    return {
      isFetching: false,
      images: []
    }
  },
  methods: {
    getImages () {
      this.isFetching = true
      LegalAdviceService.getImages(this.$route.params.boardId)
        .then(res => {
          this.images = res.data
        })
        .finally(() => {
          this.isFetching = false
        })
    }
  },
  created () {
    this.getImages()
  }
}
</script>