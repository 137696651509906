<template>
  <div>
    <v-card>
      <v-tabs
        v-model="tabs"
        fixed-tabs
      >
        <v-tab
          class="text-capitalize"
        >
          <span>Messages</span>
        </v-tab>

        <v-tab
          class="text-capitalize"
        >
          <span>Files</span>
        </v-tab>

        <v-tab
          class="text-capitalize"
        >
          <span>Details</span>
        </v-tab>

        <v-tab
          class="text-capitalize"
        >
          <span>Images</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items class="pa-0">
        <Conversations v-if="tabs == 0" :isFetching="isFetching" :isFinish="legalAdvice.isFinish" :paymentStatus="legalAdvice.status" @openGallery="openGalleryDialog"/>
        <Documents v-if="tabs == 1" />
        <Details v-if="tabs == 2" />
        <Images v-if="tabs == 3" />
      </v-tabs-items>
    </v-card>
    <ViewImages :openGallery="openGallery" :imageSrc="imageSrc" @close="openGallery = false"/>
  </div>
  <!-- <v-container fluid style="padding: 0;">
    <v-row no-gutters>
      <v-col sm="12" style="position: relative;">
        <div class="white chat-container" ref="chatContainer">
          <div v-for="(convo, index) in conversations" :key="index"
            :class="convo.user._id != $store.getters['user/user'].user._id ? 'message person-1 text-wrap' : 'message person-2 text-wrap'">
            <v-card-actions v-if="!convo.isFile" class="align-start pa-0">
              <v-avatar
                v-if="convo.user._id != $store.getters['user/user'].user._id"
                :size="28"
                class="mr-2"
                color="grey"
              ><img :src="convo.user.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'" alt="avatar">
              </v-avatar>
              <v-card-text :class="convo.user._id != $store.getters['user/user'].user._id ? 'primary white--text messageBox px-2 pb-2 pt-1' : 'grey lighten-3 messageBox px-2 pb-2 pt-1'">
                <p v-for="line in convo.message.split('\n')" :key="line.key" class="caption pt-1 mb-0">{{ line }}</p>
              </v-card-text>
            </v-card-actions>
            <v-row
              v-else
              :justify="convo.user._id != $store.getters['user/user'].user._id ? 'start' : 'end'"
              no-gutters
            >
              <v-avatar
                v-if="convo.user._id != $store.getters['user/user'].user._id"
                :size="28"
                class="mr-2"
                color="grey"
              ><img :src="convo.user.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'" alt="avatar">
              </v-avatar>
              <v-img
                @click="openGallery(convo.message)" 
                :src="convo.message"
                class="messageBox"
                max-width="80%"
                max-height="124"
              >
              </v-img>
            </v-row>
          </div>
        </div>
        
      </v-col>
    </v-row>
  </v-container> -->
</template>

<script>
import io from 'socket.io-client'
import Conversations from './MobileComponents/Conversations'
import Documents from './MobileComponents/Documents'
import Details from './MobileComponents/Details'
import Images from './MobileComponents/Images'
import TaskMixin from '@/mixins/taskMixin'
import ViewImages from '@/components/BoardComponents/ViewImages'

export default {
  name: 'MobileLegalAdvice',
  components: {
    Conversations,
    Documents,
    Details,
    Images,
    ViewImages
  },
  mixins: [
    TaskMixin
  ],
  data () {
    return {
      tabs: 0,
      isFetching: false,
      openGallery: false,
      imageSrc: '',
      socket: io(process.env.VUE_APP_SERVER)
    }
  },
  methods: {
    getLegalAdvice () {
      this.isFetching = true
      this.$store.dispatch('legalAdvice/getLegalAdviceBoard', this.$route.params.boardId)
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    openGalleryDialog (value) {
      this.imageSrc = value
      this.openGallery = true
    },
    paymentVerified () {
      this.$store.dispatch('legalAdvice/updatePaymentStatus')
    },
    socketSaver () {
      this.socket.emit('joinboard', {
        boardId: this.$route.params.boardId
      })
    },
    redirectProfile (profile) {
      this.$router.push({ name: 'ViewLawyer', params: { lawyerId: profile } })
    },
    btnLimitDate (limitDate) {
      return moment(limitDate).isSameOrAfter(new Date())
    },
    cancelTask () {
      this.$swal.queue([{
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.error,
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Cancel and Refund',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return this.$store.dispatch('legalAdvice/cancelTask', { adviceId: this.$route.params.boardId, amount: this.legalAdvice.amount })
            .then(res => {
              this.$swal({
                type: 'success',
                title: 'Successfully cancelled task!',
                text: 'Amount has been refunded and added you your wallet.'
              })
            })
            .catch(() => {
              this.$swal({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    }
  },
  computed: {
    conversations () {
      return this.$store.getters['legalAdvice/conversations']
    },
    legalAdvice () {
      return this.$store.getters['legalAdvice/legalAdvice']
    }
  },
  created () {
    this.getLegalAdvice()
    this.socketSaver()
  },
  mounted () {
    this.$store.dispatch('legalAdvice/getLegalAdviceBoard', this.$route.params.boardId)
    this.socket.on('reply', data => {
      this.$store.dispatch('legalAdvice/realTimeMessage', data)
    })
    this.socket.on('send-file', data => {
      this.$store.dispatch('legalAdvice/realTimeDocument', data)
    })
  },
  watch: {
    '$route.params.boardId'(newId, oldId) {
      if (newId) {
        this.socket.emit('boardleave', {
          boardId: oldId
        })
      }
      this.isFetching = true
      this.$store.dispatch('legalAdvice/getLegalAdviceBoard', newId)
        .finally(() => {
          this.isFetching = false
        })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.socket.emit('boardleave', {
      boardId: this.$route.params.boardId
    })
    next()
  }
}
</script>

<style scoped>
  .chat-container{
    height: calc(100vh - 7rem);
    overflow-y: auto;
    background-color: #f2f2f2;
  }
  .v-slide-group {
    display: -webkit-box !important;
  }
  .typer{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    bottom: 0;
    height: 4rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -5px 10px -5px rgba(0,0,0,.2);
  }
  .message {
    padding: 0px;
    margin: 16px 8px 0;
    max-width: 80%;
    border-radius: 5px;
  }
  .person-1 {
    float: left;
    clear: both;
  }
  .person-2 {
    float: right;
    clear: both;
  }
  .person-1 + .person-1,
  .person-2 + .person-2 {
    margin: 4px 8px 0;
  }
  .messageBox {
    border-radius: 12px;
  }
  .scroll {
    overflow-y: auto;
    padding-top: 2%;
    overflow-x: hidden;
    scroll-behavior: auto;
    scroll-behavior: smooth;
  }
</style>