<template>
   <v-card xs12 max-height="560" :class="{
    'pa-2 scroll' : !$vuetify.breakpoint.smAndDown,
    'py-2 px-3 scroll' : $vuetify.breakpoint.smAndDown
  }">
    <v-flex xs12>
      <v-card-actions class="pa-0">
        <span class="grey--text body-2">Files</span>
        <v-spacer></v-spacer>
        <span v-if="$vuetify.breakpoint.mdAndDown" class="grey--text caption">Click the file to view the options</span>
        <span v-else class="grey--text caption">Hover the file to view the options</span>
      </v-card-actions>
    </v-flex>
    <v-flex v-if="true">
      <div v-for="(doc, index) in documents" :key="index">
        <v-hover>
          <v-row align="center" justify="center" slot-scope="{ hover }" no-gutters>
            <v-col cols="2">
              <v-list-item-avatar>
                <v-icon class="white--text primary">{{ fileIcon(doc.fileName) }}</v-icon>
              </v-list-item-avatar>
            </v-col>
            <v-col cols="6">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">{{ doc.fileName }}</v-list-item-title>
                <v-list-item-subtitle class="grey--text caption">{{ doc.createdAt | dateToWords }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-col>
            <v-col cols="4" class="text-center">
              <span v-if="!hover" class="body-2">{{ doc.fileSize | fileSize }}</span>
              <v-card-actions v-else class="pa-0 justify-center">
                <v-btn small icon class="primary" @click="downloadFile(doc.fileUrl)">
                  <v-icon color="white" size="18">mdi-download</v-icon>
                </v-btn>
                <!-- <v-btn v-if="legalAdvice.isFinish" small icon class="error" @click="deleteFile(doc.fileKey, index)">
                  <v-icon color="white" size="18">mdi-delete</v-icon>
                </v-btn> -->
              </v-card-actions>
            </v-col>
          </v-row>
        </v-hover>
      </div>
    </v-flex>
    <v-flex v-else>
      <v-layout row wrap py-2 justify-center align-center>
        <span class="py-6 px-2 body-2">No files yet</span>
      </v-layout>
    </v-flex>
  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Documents',
  data () {
    return {
      imgFormats: [
        { image: '.jpg' },
        { image: '.png' },
        { image: '.jpeg' },
        { image: '.JPG' },
        { image: '.JPEG' },
        { image: '.PNG' },
      ],
      textFileFormat: [
        { text: '.txt' },
        { text: '.docx' },
        { text: '.doc' },
      ]
    }
  },
  methods: {
    downloadFile (fileUrl) {
      window.location.href = fileUrl
    },
    fileIcon (value) {
      for (let i = 0; i < this.imgFormats.length; i++) {
        if (value.includes(this.imgFormats[i].image)) {
          return 'mdi-file-image-outline'
        }
      }
      for (let i = 0; i < this.textFileFormat.length; i++) {
        if (value.includes(this.textFileFormat[i].text)) {
          return 'mdi-file-document'
        }
      }
      if (value.includes('.pdf')) {
        return 'mdi-file-pdf'
      }
    },
  },
  filters: {
    fileSize: function(value) {
      if (value <= 1000000) {
        return value ? (value / 1024).toFixed(1) + ' KB' : ''
      }
      else if (value >= 1000000) {
        return value ? (value / 1024 / 1024).toFixed(1) + ' MB' : ''
      }
    },
    dateToWords: function (value) {
      return value ? moment(value).format('MMMM DD YYYY') : ''
    }
  },
  computed: {
    documents () {
      return this.$store.getters['legalAdvice/documents']
    },
    legalAdvice () {
      return this.$store.getters['legalAdvice/legalAdvice']
    }
  }
}
</script>

<style scoped>
  .scroll {
    overflow-y: auto;
    padding-top: 2%;
  }
</style>