<template>
  <v-container :class="$vuetify.breakpoint.mdAndDown ? 'pa-0' : 'px-5 my-4'" grid-list-md>
    <!-- <v-flex xs12 class="pa-2 primary text-center" v-if="!isFetching && legalAdvice.status === 'Payment Verified'">
      <v-icon small class="pr-2 text-xs-center" color="white">error</v-icon>
      <span class="white--text body-2 pr-4">Important! Task must be finished on or before {{ legalAdvice.limitDate | localDate }}</span>
      <v-btn v-if="!$store.getters['user/user'].user.isLawyer && !btnLimitDate(legalAdvice.limitDate)" class="error" @click="cancelTask()" depressed small>Cancel and Refund</v-btn>
    </v-flex> -->
    <v-layout justify-center>
      <v-flex xs12 lg10 xl7>
        <v-flex xs12 class="pa-2 error text-xs-center" v-if="legalAdvice.status === 'Cancelled'">
          <v-icon class="pr-2 text-xs-center" color="white">error</v-icon>
          <span class="white--text subheading pr-4">Task has been cancelled!</span>
        </v-flex>
        <v-card>
          <v-row v-if="!isFetching" align="center" no-gutters>
            <v-col
              :cols="!$vuetify.breakpoint.smAndDown ? '1' : '2'"
              class="pl-4 pt-4 pb-3"
            >
              <v-img
                v-if="!legalAdvice.problem.isAnonymous && $store.getters['user/user'].user.isLawyer"
                :src="legalAdvice.client.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
                alt="avatar"
                width="50"
                style="border-radius: 8%"
              ></v-img>
              <v-img
                v-if="legalAdvice.problem.isAnonymous && $store.getters['user/user'].user.isLawyer"
                src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png"
                alt="avatar"
                width="50"
                style="border-radius: 8%"
              ></v-img>
              <v-img
                v-if="!$store.getters['user/user'].user.isLawyer"
                :src="legalAdvice.lawyer.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
                alt="avatar"
                width="50"
                style="border-radius: 8%"
              ></v-img>
            </v-col>
            <v-col
              :cols="!$vuetify.breakpoint.mdAndDown ? '5' : '6'"
              :class="$vuetify.breakpoint.mdAndDown ? 'pl-2' : ''"
            >
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title class="viewMorePointer" v-if="!$store.getters['user/user'].user.isLawyer" @click="redirectProfile(legalAdvice.lawyer._id)">{{ `Atty. ${legalAdvice.lawyer.fullName}` }}</v-list-item-title>
                  <v-list-item-title v-if="$store.getters['user/user'].user.isLawyer && legalAdvice.problem.isAnonymous">Anonymous User</v-list-item-title>
                  <v-list-item-title v-if="$store.getters['user/user'].user.isLawyer && !legalAdvice.problem.isAnonymous">{{ legalAdvice.client.fullName }}</v-list-item-title>
                  <v-list-item-subtitle class="grey--text">
                    <div v-if="!$store.getters['user/user'].user.isLawyer">
                    {{ legalAdvice.lawyer.city }},
                    {{ legalAdvice.lawyer.province }}
                    </div>
                    <div v-if="$store.getters['user/user'].user.isLawyer && !legalAdvice.problem.isAnonymous">
                      {{ legalAdvice.client.city }},
                      {{ legalAdvice.client.province }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col v-if="!$vuetify.breakpoint.mdAndDown" cols="6" class="pr-0">
              <v-row v-if="legalAdvice.amount != 0" justify="end" align="center" no-gutters>
                <v-col cols="4" class="text-center">
                  <p class="grey--text mb-0">Status</p>
                  <p class="primary--text mb-0 font-weight-medium">{{ legalAdvice.payment ? legalAdvice.payment.status : 'Pending' }}</p>
                </v-col>
                <v-col cols="3" class="text-center">
                  <p class="grey--text mb-0">Amount</p>
                  <p class="primary--text mb-0 font-weight-medium">{{ legalAdvice.amount | taskPHCurrency }}</p>
                </v-col>
                <v-col v-if="!$store.getters['user/user'].user.isLawyer" cols="4">
                  <v-btn
                    v-if="legalAdvice.status === 'Pending Payment' && !paymentUploaded"
                    depressed
                    block
                    color="primary"
                    @click="uploadPaymentModal = true"
                  >Confirm Payment</v-btn>
                  <v-btn
                    v-else-if="legalAdvice.status === 'Pending Payment' && !legalAdvice.isFinish"
                    depressed
                    block
                    color="info"
                    @click="editPaymentModal = true"
                  >Edit Payment</v-btn>
                  <v-btn
                    v-else-if="legalAdvice.status === 'Payment Verified' && !legalAdvice.isFinish"
                    depressed
                    color="primary"
                    @click="rateLawyerModal = true"
                  >Complete Task</v-btn>
                </v-col>
              </v-row>
              <!-- <v-card-actions v-if="legalAdvice.amount != 0"  class="pa-0 text-xs-center">
                <p>Status <span class="primary--text subheading font-weight-medium">{{ legalAdvice.payment ? legalAdvice.payment.status : legalAdvice.status }}</span></p>
                <v-spacer></v-spacer>
                <p>Amount <span class="primary--text subheading font-weight-medium">{{ legalAdvice.amount | taskPHCurrency }}</span></p>
                <v-spacer></v-spacer>
                <p v-if="$store.getters['user/user'].user.isLawyer">To Received: <span class="primary--text font-weight-medium">{{ legalAdvice.amount | amountReceived }}</span></p>
                <v-btn
                  v-else-if="legalAdvice.status === 'Pending Payment' && !paymentUploaded"
                  depressed
                  color="primary"
                  @click="uploadPaymentModal = true"
                >Confirm Payment</v-btn>
                <v-btn
                  v-else-if="legalAdvice.status != 'Payment Verified' && !legalAdvice.isFinish"
                  depressed
                  color="info"
                  @click="editPaymentModal = true"
                >Edit Payment</v-btn>
                <v-btn
                  v-else-if="legalAdvice.status === 'Payment Verified' && !legalAdvice.isFinish"
                  depressed
                  color="primary"
                  @click="rateLawyerModal = true"
                >Rate and Complete Task</v-btn>
              </v-card-actions> -->
              <v-card-actions v-else>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="legalAdvice.status === 'Payment Verified' && !$store.getters['user/user'].user.isLawyer && !legalAdvice.isFinish"
                  depressed
                  color="primary"
                  @click="rateLawyerModal = true"
                >Rate and Complete Task</v-btn>
                <p v-if="$store.getters['user/user'].user.isLawyer" class="primary--text font-weight-medium">Free Advice</p>
              </v-card-actions>
            </v-col>

            <!-- Mobile View -->
            <v-layout v-else row wrap :justify-center="!$store.getters['user/user'].user.isLawyer" xs12 px-4>
              <v-flex xs4 text-center>
                <p class="grey--text mb-0 body-2">Status</p>
                <p class="primary--text font-weight-medium body-2">{{ legalAdvice.payment ? legalAdvice.payment.status : 'Pending' }}</p>
              </v-flex>
              <v-flex xs4 text-center>
                <p class="grey--text mb-0 body-2">Amount</p>
                <p class="primary--text font-weight-medium body-2">{{ legalAdvice.amount | taskPHCurrency }}</p>
              </v-flex>
              <v-flex v-if="$store.getters['user/user'].user.isLawyer" xs4 text-center>
                <p class="grey--text mb-0 body-2">To Received</p>
                <p class="primary--text font-weight-medium body-2">{{ legalAdvice.amount | amountReceived }}</p>
              </v-flex>
              <v-flex v-else xs12>
                <v-btn
                  v-if="legalAdvice.status === 'Pending Payment' && !paymentUploaded"
                  depressed
                  block
                  color="primary"
                  class="mb-3"
                  @click="uploadPaymentModal = true"
                >Confirm Payment</v-btn>
                <v-btn
                  v-else-if="legalAdvice.status === 'Pending Payment' && !legalAdvice.isFinish"
                  depressed
                  block
                  color="info"
                  class="mb-3"
                  @click="editPaymentModal = true"
                >Edit Payment</v-btn>
              </v-flex>
              <v-flex v-if="!legalAdvice.isFinish" xs12 pb-3>
                <v-btn
                  v-if="legalAdvice.status === 'Payment Verified' && !$store.getters['user/user'].user.isLawyer && !legalAdvice.isFinish"
                  depressed
                  block
                  color="primary"
                  @click="rateLawyerModal = true"
                >Rate and Complete Task</v-btn>
                <v-btn
                  block
                  text
                  color="error"
                  v-if="legalAdvice.amount > 0 && legalAdvice.status === 'Payment Verified' && !$store.getters['user/user'].user.isLawyer"
                  @click="disputeModal = true"
                >I want to dispute</v-btn>
                <p v-if="$store.getters['user/user'].user.isLawyer && legalAdvice.isFree" class="primary--text font-weight-medium">Free Advice</p>
              </v-flex>
            </v-layout>
            <!-- End Mobile View -->

          </v-row>
          <content-placeholders v-else class="px-4 py-3" :rounded="true">
            <content-placeholders-heading :img="true"/>
          </content-placeholders>
          <v-divider></v-divider>
          <v-row align="center" class="pa-3" no-gutters>
            <v-col v-if="!isFetching">
              <p :class="$vuetify.breakpoint.mdAndUp ? 'mb-1 font-weight-medium' : 'mb-1 font-weight-medium body-1'">{{ legalAdvice.problem.title }}</p>
              <p :class="$vuetify.breakpoint.mdAndUp ? 'mb-0' : 'mb-0 body-2'">{{ legalAdvice.problem.description }}</p>
            </v-col>
            <v-col v-else class="px-4 pt-3 pb-2">
              <content-placeholders :rounded="true">
                <content-placeholders-text :lines="6"/>
              </content-placeholders>
            </v-col>
          </v-row>
        </v-card>
        
      </v-flex>
    </v-layout>
    
    <RateLawyer
      v-if="!isFetching &&
      legalAdvice.status === 'Payment Verified'"
      :visible="rateLawyerModal"
      :amount="legalAdvice.amount"
      :lawyerId="legalAdvice.lawyer._id"
      :lawyerName="legalAdvice.lawyer.fullName"
      :problemTitle="legalAdvice.problem.title"
      :problemId="legalAdvice.problem._id"
      :clientId="legalAdvice.client._id"
      :lawyerEmail="legalAdvice.lawyer.email"
      :isAnonymous="legalAdvice.problem.isAnonymous"
      @close="rateLawyerModal = false"
    />
    <UploadPayment
      v-if="!isFetching"
      :visible="uploadPaymentModal"
      :amount="legalAdvice.amount"
      :boardId="legalAdvice._id"
      :lawyerId="legalAdvice.lawyer._id"
      :lawyerEmail="legalAdvice.lawyer.email"
      :clientName="legalAdvice.client.fullName"
      :problem="legalAdvice.problem._id"
      :isAnonymous="legalAdvice.problem.isAnonymous"
      service="Legal Advice"
      @successWithDeposit="paymentUploaded = true"
      @successWithFunds="paymentUploaded = true, paymentVerified()"
      @close="uploadPaymentModal = false"
    />
    <EditPayment
      v-if="!isFetching && legalAdvice.payment && legalAdvice.status != 'Payment Verified'"
      :visible="editPaymentModal"
      :paymentId="legalAdvice.payment._id"
      :cloudinaryPublicId="legalAdvice.payment.cloudinaryPublicId"
      :amount="legalAdvice.amount"
      :boardId="legalAdvice._id"
      service="Legal Advice"
      @successWithFunds="paymentVerified()"
      @close="editPaymentModal = false"
    />
    <Dispute 
      v-if="!isFetching && legalAdvice.status === 'Payment Verified' && $vuetify.breakpoint.mdAndDown"
      :visible="disputeModal" 
      :lawyerId="legalAdvice.lawyer._id" 
      :clientId="legalAdvice.client._id" 
      :problemId="legalAdvice.problem._id" 
      :problemTitle="legalAdvice.problem.problemTitle"
      :boardId="$route.params.boardId" 
      :lawyerEmail="legalAdvice.lawyer.email"
      :isAnonymous="legalAdvice.problem.isAnonymous"
      @close="disputeModal = false" />
  </v-container>
</template>

<script>
import io from 'socket.io-client'
import moment from 'moment'
import Conversations from '@/components/BoardComponents/NewConversations'
import UploadPayment from '@/components/BoardComponents/UploadPayment'
import EditPayment from '@/components/BoardComponents/EditPayment'
import RateLawyer from '@/components/BoardComponents/NewRateLawyer'
import Dispute from '@/components/BoardComponents/Dispute'
import TaskMixin from '@/mixins/taskMixin'

export default {
  name: 'Legal-Advice',
  components: {
    Conversations,
    UploadPayment,
    EditPayment,
    RateLawyer,
    Dispute
  },
  mixins: [
    TaskMixin
  ],
  data () {
    return {
      rateLawyerModal: false,
      disputeModal: false,
      isFetching: false,
      uploadPaymentModal: false,
      editPaymentModal: false,
      paymentUploaded: false,
      socket: io(process.env.VUE_APP_SERVER)
    }
  },
  methods: {
    getLegalAdvice () {
      this.isFetching = true
      this.$store.dispatch('legalAdvice/getLegalAdviceBoard', this.$route.params.boardId)
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    paymentVerified () {
      this.$store.dispatch('legalAdvice/updatePaymentStatus')
    },
    socketSaver () {
      this.socket.emit('joinboard', {
        boardId: this.$route.params.boardId
      })
    },
    redirectProfile (profile) {
      this.$router.push({ name: 'ViewLawyer', params: { lawyerId: profile } })
    },
    btnLimitDate (limitDate) {
      return moment(limitDate).isSameOrAfter(new Date())
    },
    cancelTask () {
      this.$swal.queue([{
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.error,
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Cancel and Refund',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return this.$store.dispatch('legalAdvice/cancelTask', { adviceId: this.$route.params.boardId, amount: this.legalAdvice.amount })
            .then(res => {
              this.$swal({
                type: 'success',
                title: 'Successfully cancelled task!',
                text: 'Amount has been refunded and added you your wallet.'
              })
            })
            .catch(() => {
              this.$swal({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    }
  },
  computed: {
    legalAdvice () {
      return this.$store.getters['legalAdvice/legalAdvice']
    }
  },
  created () {
    this.getLegalAdvice()
    this.socketSaver()
  },
  mounted () {
    this.$store.dispatch('legalAdvice/getLegalAdviceBoard', this.$route.params.boardId)
    this.socket.on('reply', data => {
      this.$store.dispatch('legalAdvice/realTimeMessage', data)
    })
    this.socket.on('send-file', data => {
      this.$store.dispatch('legalAdvice/realTimeDocument', data)
    })
  },
  watch: {
    '$route.params.boardId'(newId, oldId) {
      if (newId) {
        this.socket.emit('boardleave', {
          boardId: oldId
        })
      }
      this.isFetching = true
      this.$store.dispatch('legalAdvice/getLegalAdviceBoard', newId)
        .finally(() => {
          this.isFetching = false
        })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.socket.emit('boardleave', {
      boardId: this.$route.params.boardId
    })
    next()
  }
}
</script>

<style scoped>
  >>> .remove-tile-padding .v-list__tile {
    padding-left: 0;
    padding-right: 0;
  }
</style>
